<template>
  <div class="elv-screening-container">
    <div class="elv-screening-content">
      <slot name="screening" />
    </div>
    <div>
      <div class="elv-financials-header-main-right">
        <el-button
          v-if="props.showEdit"
          :disabled="props.editDisabled"
          link
          class="elv-financials-header-main-right__edit"
          @click="onEdit"
        >
          <SvgIcon name="reports-batch-edit" :width="iconLength" :height="iconLength" />
          {{ t('button.edit') }}({{ formatNumber(props.editCount) }})
        </el-button>

        <el-button
          v-if="props.showIntegrations && isCreatorProject && false"
          link
          class="elv-financials-header-main-right__integrations"
          @click="onJumpEntitySetting"
        >
          <SvgIcon name="reports-integrations" :width="iconLength" :height="iconLength" />
          {{ t('common.integrations') }}
        </el-button>

        <el-button
          v-if="showExport"
          :loading="exportLoading"
          class="elv-financials-header-main-right__export"
          link
          @click="onExport"
        >
          <SvgIcon name="reports-export" :width="iconLength" :height="iconLength" />
          {{ transformI18n(props.exportText) }}
        </el-button>

        <slot name="operating" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { $t, transformI18n } from '@/i18n'
import { formatNumber } from '@/lib/utils'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global/index'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const props = defineProps({
  showIntegrations: {
    type: Boolean,
    default: false
  },
  showEdit: {
    type: Boolean,
    default: false
  },
  editCount: {
    type: Number,
    default: 0
  },
  editDisabled: {
    type: Boolean,
    default: false
  },
  showExport: {
    type: Boolean,
    default: false
  },
  exportLoading: {
    type: Boolean,
    default: false
  },
  iconLength: {
    type: String,
    default: '16'
  },
  buttonTextColor: {
    type: String,
    default: '#1e2024'
  },
  exportText: {
    type: String,
    default: $t('button.export')
  }
})
const { t } = useI18n()
const router = useRouter()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()

const emit = defineEmits(['onEdit', 'onExport', 'onSyncIntegration'])

const isCreatorProject = computed(() => {
  const currentProject = find(globalStore.projectList, (item: any) => {
    return item.projectId === entityStore.entityDetail?.projectId
  })
  return userGlobalStore.user?.userId === currentProject?.userId
})

const onJumpEntitySetting = () => {
  router.push({
    name: 'project-settings',
    params: { projectId: entityStore.entityDetail?.projectId },
    query: { entityId: entityStore.entityDetail?.entityId }
  })
}

const onExport = () => {
  emit('onExport')
}

const onEdit = () => {
  emit('onEdit')
}
</script>

<style lang="scss" scoped>
.elv-screening-container {
  padding: 12px 0px 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .elv-screening-content {
    display: flex;
    align-items: center;
  }
}

.elv-financials-header-main-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.elv-financials-header-main-right__edit,
.elv-financials-header-main-right__integrations {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: v-bind('buttonTextColor');
  cursor: pointer;

  svg {
    color: v-bind('buttonTextColor');
    margin-right: 6px;
  }

  &:not(.is-disabled):hover,
  &:not(.is-disabled):focus,
  &:not(.is-disabled):active {
    background-color: #fff;
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}

.elv-financials-header-main-right__export {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: v-bind('buttonTextColor');
  cursor: pointer;
  margin-left: 6px;

  svg {
    color: v-bind('buttonTextColor');
    margin-right: 8px;
  }

  &:hover {
    color: #1343bf;
    background-color: #fff;

    svg {
      fill: #1343bf;
    }
  }
}
</style>
<style lang="scss">
.elv-screening-item {
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #dde1e6;
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }

  .elv-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.elv-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
